import { useEffect, useState } from 'react'
import { getCIPLogoutPath } from 'helpers/getForgeRockvalue/getForgeRockValue'
import SplashScreen from 'components/feedback/SplashScreen'
import { getUserIdentityPlatform } from 'helpers/getUserIdentityPlatform'
import { Identity_Platform } from 'constants/identityPlatforms'
import { getAzureLogoutPath } from 'helpers/getAzureADValue/getAzureADValue'
import Cookies from 'js-cookie'

const LogoutPage = () => {
  const [redirectUrl, setRedirectUrl] = useState('')

  useEffect(() => {
    if (!redirectUrl) {
      const setUrl = async () => {
        let userIdentityPlatform =
          Cookies.get('bpf-idp') ?? getUserIdentityPlatform(window.localStorage.getItem('isInternal') ?? 'false')
        let url
        switch (userIdentityPlatform) {
          case Identity_Platform.CIP:
            url = await getCIPLogoutPath()
            break
          case Identity_Platform.AAD:
            url = await getAzureLogoutPath()
            break
        }
        setRedirectUrl(url)
      }
      setUrl()
    }
  }, [redirectUrl])

  if (redirectUrl) window.location.assign(redirectUrl)

  return <SplashScreen></SplashScreen>
}

export default LogoutPage
