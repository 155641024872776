import styled from 'styled-components'
import { getCardTypeContent } from 'pages/Cards/CardOrderPage/helpers'
import { getPinLabel } from './index'
import { CARD_GASOLEO_PROFESIONAL_Label } from 'constants/cards'

const Address = styled.address`
  font-style: normal;

  > ol,
  li {
    list-style: none;
    padding: 0;
  }
  li {
    line-height: 1.5rem;
  }
`

const YES = 'Y'

/**
 * Transforms the response of a full card detail request
 * to inject content and prepare for React render
 *
 * @param {Object} data - the raw response from a full card detail request
 * @param {Object} content - content object containing keys for cards page
 * @returns {Object}
 */
const formatCardDetail = (data = {}, content = {}, parentData = {}) => {
  const pinMethod = data.general?.pinMethod
  const pinLabel = content[`manage_cards_pin_option_${getPinLabel(pinMethod).toLowerCase()}`] || pinMethod`1`

  return {
    accountDetails: [
      { label: content.manage_cards_full_details_labels_parent_id, value: data.accountDetails?.parentId },
      { label: content.manage_cards_full_details_labels_authority_id, value: data.accountDetails?.authorityId },
      {
        label: content.manage_cards_edit_full_details_general_pin_delivery_address,
        value: (
          <Address>
            <ol>
              {Object.values(data.accountDetails?.address).map((line, index) => (
                <li key={line || index}>{line}</li>
              ))}
            </ol>
          </Address>
        )
      }
    ],
    general: [
      { label: content.manage_cards_full_details_labels_cost_centre, value: data.general?.costCenter },
      { label: content.manage_cards_full_details_labels_sub_cost_centre, value: data.general?.subCostCenter },
      { label: content.manage_cards_full_details_labels_pin_method, value: pinLabel },
      {
        label: content.manage_cards_full_details_labels_odometer,
        value:
          data.general?.odometer === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      },
      {
        label: content.manage_cards_full_details_labels_additional_info,
        value:
          data.general?.addInfo === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      },
      { label: content.manage_cards_full_details_labels_card_last_used, value: parentData.lastUsed },
      { label: content.manage_cards_full_details_labels_card_status, value: parentData.cardStatus }
    ],
    restrictions: [
      { label: content.manage_cards_full_details_labels_card_profile_name, value: data.restrictions?.profileName },
      { label: content.manage_cards_full_details_labels_service_restrictions, value: data.restrictions?.serviceCode }
    ],
    offers: [
      /*
      {
        label: content.manage_cards_full_details_summary_ppd_scheme,
        value:
          data.offers?.ppdScheme === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      },
      */
      {
        label: content.manage_cards_full_details_labels_austrian_road_tax,
        value:
          data.offers?.austrianRoadTaxInd === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      },
      {
        label: CARD_GASOLEO_PROFESIONAL_Label,
        value:
          data.embossingDetails?.gasoleoProfessional === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      }
    ],
    bpMe: [
      {
        label: content.manage_cards_full_details_labels_bpme_enabled,
        value:
          data.virtualCard?.bpMeEnabled === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      },
      { label: content.manage_cards_full_details_labels_card_provisioned, value: data.virtualCard?.cardProvisioned },
      { label: content.manage_cards_full_details_labels_mobile_last_used, value: data.virtualCard?.mobileLastUsed }
    ],
    embossingDetails: [
      {
        label: content.manage_cards_full_details_labels_card_type,
        value: data.embossingDetails?.cardType
          ? getCardTypeContent(data.embossingDetails.cardType, 'card_type', content)
          : '-'
      },
      /*
      {
        label: content.manage_cards_full_details_labels_ivmh,
        value:
          data.embossingDetails?.ivmh === YES
            ? content.manage_cards_yes || 'manage_cards_yes'
            : content.manage_cards_no || 'manage_cards_no'
      },
      */
      ...[
        {
          label: content.manage_cards_full_details_summary_fuel_and_charge,
          value:
            data.embossingDetails?.fuelAndCharge === YES
              ? content.manage_cards_yes || 'manage_cards_yes'
              : content.manage_cards_no || 'manage_cards_no'
        }
      ],
      { label: content.manage_cards_full_details_labels_cardholder_name, value: data.embossingDetails?.cardHolderName },
      {
        label: content.manage_cards_full_details_labels_vehicle_registration,
        value: data.embossingDetails?.vehicleRegistration
      },
      { label: content.manage_cards_full_details_labels_embossed_line_2, value: data.embossingDetails?.embossedLine2 },
      {
        label: content.manage_cards_full_details_labels_national_international_use,
        value:
          data.restrictions?.international === YES
            ? content.manage_cards_national_international || 'manage_cards_national_international'
            : content.manage_cards_national || 'manage_cards_national'
      },
      {
        label: content.manage_cards_full_details_labels_card_serial_number,
        value: data.embossingDetails?.cardSerialNumber
      },
      { label: content.manage_cards_full_details_labels_card_expiry_date, value: data.embossingDetails?.expiry }
    ]
  }
}

export default formatCardDetail
