import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useRootStore } from 'contexts/StoreContext'
import { QUERY_KEY } from './useApiGetAuthorityDetails'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const queryFn = async (payload: any) => {
  if (payload != null) {
    if (payload?.carddetails?.address?.addressLine1 != '' && payload?.carddetails?.sendToAddress != 2)
      payload.carddetails.sendToAddress = '2'
  }
  if (payload.cardSetup && payload.cardSetup.parentNumber === 'null') {
    payload.cardSetup.parentNumber = null
  }

  let errorMsg = ''
  let showValidationMessage = true

  try {
    const response = await axios({
      method: 'post',
      url: '/api/cards/cardorder',
      data: payload
    })

    let cardOrderData

    if (response.data.cardOrderDeliveryType) {
      cardOrderData = {
        correlationId: response.data.correlationId,
        responseCode: response.data.responseCode,
        deliveryType: {
          image: response.data.cardOrderDeliveryType.image,
          CardType: response.data.cardOrderDeliveryType.cardType,
          noOfCardsOrdered: response.data.cardOrderDeliveryType.noOfCardsOrdered,
          serial: response.data.cardOrderDeliveryType.serial,
          serviceCode: response.data.cardOrderDeliveryType.serviceCode,
          countryCode: response.data.cardOrderDeliveryType.countryCode,
          expiryMonth: response.data.cardOrderDeliveryType.expiryMonth,
          expiryYear: response.data.cardOrderDeliveryType.expiryYear,
          embossingLine1: response.data.cardOrderDeliveryType.embossingLine1,
          embossingLine2: response.data.cardOrderDeliveryType.embossingLine2,
          embossingLine3: response.data.cardOrderDeliveryType.embossingLine3,
          Authority: response.data.cardOrderDeliveryType.authority
        }
      }
    } else if (response.data.errorDetails) {
      errorMsg = response.data.errorDetails.errorMessage
      showValidationMessage = false
      throw {
        message: errorMsg,
        showValidationMessage
      }
    } else {
      cardOrderData = response.data
    }
    return cardOrderData
  } 
  catch (e) {
    const error = e as AxiosError
    const errorData = error.response?.data

    if (error.response?.status === 504) {
      errorMsg = 'The server took too long to respond. Please try again later.';
      } 
    else if (errorData?.errors) {
      errorMsg = Object.values(errorData.errors).flat().join(' | ');
    }
    throw {
      error: error.response?.data,
      message: errorMsg,
      showValidationMessage: !showValidationMessage
    }
  }
}

const useApiPostCardOrderSubmit = (
  onSuccess: (arg0: Response) => void,
  onError: (message: string, showValidationMessage: boolean) => void
) => {
  const { userStore } = useRootStore()
  const { selectedAuthorityId } = userStore
  const queryClient = useQueryClient()

  return useMutation<Response, AxiosError>(queryFn, {
    mutationKey: 'card-order-submit',
    onSuccess: response => {
      onSuccess(response)
      queryClient.invalidateQueries(QUERY_KEY + selectedAuthorityId)
    },
    onError: error => {
      const { message, showValidationMessage } = error as any
      onError(message, showValidationMessage)
    }
  })
}

export default useApiPostCardOrderSubmit
