import { useState, useEffect, useRef } from 'react'
import { toJS } from 'mobx'

import { useRootStore } from 'contexts/StoreContext'

import { Button } from '@bp-digital/component-button'
import { Text } from '@bp-digital/component-typography'
import { StateMessage } from '@bp-digital/component-state-message'
import { AlertModal } from '@bp-digital/component-alert-modal'

import Paper from 'components/surfaces/Paper'
import {
  Wrapper,
  UploadRow,
  UploadFileSelect,
  HiddenFileSelectInput,
  UploadFileButton,
  DownloadRow,
  FileColumn,
  FileItem,
  FileDownloadColumn,
  Spacer,
  FakeButtonWrapper,
  FakeInputButton,
  FakeFileLabel
} from './DocumentsWidget.styles'

import useApiGetStaticFileList from 'hooks/api/useApiGetStaticFileList'
import useApiPostStaticFile from 'hooks/api/useApiPostStaticFile'
import useApiDeleteStaticFile from 'hooks/api/useApiDeleteStaticFile'
import useContent from 'hooks/useContent'
import Cookies from 'js-cookie'
import axios from 'axios'

const formatDate = input => {
  const date = new Date(input)
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
}

const DocumentsWidget = ({ isManageDisabled, isDownloadDisabled }) => {
  const content = useContent('userdashboard') || {}

  const { userStore } = useRootStore()
  const { authorityId } = toJS(userStore.selectedAuthority)

  const [formData, setFormData] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [deletedFile, setDeletedFile] = useState(null)

  const [isLoading, setIsLoading] = useState(true)
  const [visible, setVisible] = useState(false)

  const [selectFileError, setSelectFileError] = useState(null)

  const fileElementRef = useRef(null)
  const pricelistfromAzure = Cookies.get('pricelist-azure') == 'true' ? true : false
  const {
    isSuccess: uploadIsSuccess,
    isLoading: uploadIsLoading,
    isError: uploadIsError,
    error: uploadError,
    reset: uploadReset,
    mutate: uploadMutate
  } = useApiPostStaticFile(pricelistfromAzure)

  const {
    isSuccess: deleteIsSuccess,
    isLoading: deleteIsLoading,
    mutate: deleteMutate
  } = useApiDeleteStaticFile(authorityId, pricelistfromAzure)

  const {
    data,
    status: fileStatus,
    refetch: fileRefetch,
    isRefetching: fileIsRefetching,
    error: fileError
  } = useApiGetStaticFileList(authorityId, pricelistfromAzure)

  const Contents = !data || !Object.keys(data.data).length ? [] : data.data

  const onFileSelect = e => {
    e.preventDefault()
    setSelectedFile(e.target.files[0])

    const extension = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.') + 1)

    !['xls', 'xlsx', 'XLS', 'XLSX'].includes(extension)
      ? setSelectFileError(`${extension} files are not supported`)
      : setSelectFileError(null)
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64File = reader.result.split(',')[1] // Remove the data URL prefix
      const jsonObject = {
        authorityId: authorityId,
        file: {
          fileName: e.target.files[0].name,
          length: e.target.files[0].size,
          content: base64File,
          contentDisposition: `attachment; filename="${e.target.files[0].name}"`,
          contentType: e.target.files[0].type || 'application/vnd.ms-excel'
        }
      }
      setFormData(jsonObject)
    }
    reader.readAsDataURL(e.target.files[0])
  }
  function downloadFileFromResponse(responseData) {
    // Extract the relevant information from the response
    const { fileContents, contentType, fileDownloadName } = responseData

    // Convert Base64 string to a Blob object
    const byteCharacters = atob(fileContents)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: contentType })

    // Create a temporary anchor element and trigger the download
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileDownloadName // Set the file name for the download
    document.body.appendChild(link) // Required for Firefox
    link.click()

    // Clean up by revoking the Object URL and removing the temporary link
    URL.revokeObjectURL(blobUrl)
    link.remove()
  }
  const downloadFile = async e => {
    let url = '/api/transactions/pricingList/DownloadAsync'
    const downloadUrl = url + '?authorityIds=' + authorityId + '&key=' + encodeURIComponent(e.Key).substring(4)
    const response = await axios.get(downloadUrl)
    downloadFileFromResponse(response.data)
  }
  const onFileDeleteHandler = file => {
    setVisible(true)
    setDeletedFile({ ...file, authorityId })
  }

  useEffect(() => {
    setFormData(null)
    fileElementRef.current ? (fileElementRef.current.value = '') : (fileElementRef.current = null)
    setSelectedFile(null)
    setDeletedFile(null)
    uploadReset()

    fileRefetch()
  }, [uploadIsSuccess, deleteIsSuccess]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(uploadIsLoading || deleteIsLoading || fileStatus === 'loading' || fileIsRefetching)
  }, [uploadIsLoading, deleteIsLoading, fileStatus, fileIsRefetching])

  useEffect(() => {
    setIsLoading(true)
    setSelectedFile(null)
    setDeletedFile(null)
    uploadReset()
    fileRefetch()
  }, [authorityId]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper
      title={content.document_widget_title || 'document_widget_title'}
      button={false}
      commands={false}
      testId='static-docs-testid'
    >
      <Wrapper>
        {!isManageDisabled && (
          <>
            <HiddenFileSelectInput
              id='hiddenSelectedFile'
              type='file'
              onChange={onFileSelect}
              disabled={isManageDisabled || uploadIsLoading}
              ref={fileElementRef}
              accept='.xls,.xlsx'
            />
            <UploadRow>
              <UploadFileSelect>
                <FakeButtonWrapper>
                  <FakeInputButton
                    htmlFor='hiddenSelectedFile'
                    className={isManageDisabled || uploadIsLoading ? 'disabled' : ''}
                  >
                    {content.document_widget_choose_file || 'document_widget_choose_file'}
                  </FakeInputButton>
                  {selectFileError && (
                    <StateMessage
                      text={`${
                        content.document_widget_error_prefix || 'document_widget_error_prefix'
                      } ${selectFileError}`}
                      state='danger'
                      iconName='Info'
                    />
                  )}
                  <FakeFileLabel>{!selectFileError && selectedFile && selectedFile.name}</FakeFileLabel>
                </FakeButtonWrapper>
              </UploadFileSelect>
              <UploadFileButton>
                <Button
                  iconName='Upload'
                  type='button'
                  onClick={() => {
                    uploadMutate(formData)
                  }}
                  disabled={isManageDisabled || !formData || uploadIsLoading || !!selectFileError}
                  isLoading={uploadIsLoading}
                >
                  {content.document_widget_upload_file || 'document_widget_upload_file'}
                </Button>
              </UploadFileButton>
            </UploadRow>
            {uploadIsError && (
              <StateMessage
                text={`${content.document_widget_error_prefix || 'document_widget_error_prefix'} ${
                  uploadError?.message
                }`}
                state='danger'
                iconName='Info'
              />
            )}
            {uploadError?.error?.message && (
              <StateMessage text={`${uploadError?.error?.message}`} state='danger' iconName='Info' />
            )}
          </>
        )}
        {isLoading && <div>{content.document_widget_loading_files || 'document_widget_loading_files'}</div>}
        {fileStatus === 'error' && (
          <StateMessage
            text={`${content.document_widget_error_prefix} ${fileError.message}`}
            state='danger'
            iconName='Info'
          />
        )}

        <DownloadRow>
          <FileColumn>
            {!Contents?.length && !isLoading && fileStatus !== 'error' && (
              <StateMessage
                text={content.document_widget_no_files || 'document_widget_no_files'}
                state='neutral'
                iconName='Info'
              />
            )}

            {!!Contents.length &&
              !isLoading &&
              Contents[0].Key &&
              Contents.map((f, i) => {
                return (
                  <FileItem key={`key-${i}-${f.ETag}-${f.Key}`} data-id={f.Key}>
                    <Text as='span' display='inline'>
                      {f.Key.split('/')[1]}
                    </Text>
                    <Text>
                      <em>{formatDate(f.LastModified)}</em>
                    </Text>
                  </FileItem>
                )
              })}
          </FileColumn>
          <FileDownloadColumn>
            {!isLoading &&
              Contents.map((f, i) => {
                return (
                  <FileItem key={`key-button-${i}-${f.ETag}`} alignRight={true}>
                    <Button
                      appearance='primary'
                      iconName='Download'
                      onClick={() => downloadFile(f)}
                      disabled={isDownloadDisabled || !Contents[0].Key}
                    >
                      {content.document_widget_download_file || 'document_widget_download_file'}
                    </Button>
                    {!isManageDisabled && (
                      <>
                        <Spacer />
                        <Button
                          appearance='secondary'
                          iconName='RemoveLarge'
                          iconPosition='end'
                          onClick={() => onFileDeleteHandler({ key: f.Key })}
                          disabled={isManageDisabled || isDownloadDisabled || !Contents[0].Key}
                        >
                          {content.document_widget_delete_file || 'document_widget_delete_file'}
                        </Button>
                      </>
                    )}
                  </FileItem>
                )
              })}
            <AlertModal
              primaryAction={{
                text: content.document_widget_delete_file || 'document_widget_delete_file',
                onClick: () => {
                  deleteMutate(deletedFile)
                  setVisible(false)
                }
              }}
              secondaryAction={{
                text: content.document_widget_cancel || 'document_widget_cancel',
                onClick: () => {
                  setVisible(false)
                  setDeletedFile(null)
                }
              }}
              visible={visible}
              state='warning'
              hasCloseButton={false}
              onDismiss={() => {
                setVisible(false)
                setDeletedFile(null)
              }}
              title={content.document_widget_are_you_sure || 'document_widget_are_you_sure'}
              text={deletedFile?.key.split('/')[1]}
            />
          </FileDownloadColumn>
        </DownloadRow>
      </Wrapper>
      <iframe name='iframe' title='iframe' height='0' width='0' />
    </Paper>
  )
}

export default DocumentsWidget
