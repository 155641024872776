import { action, computed, observable, makeObservable, toJS } from 'mobx'
import axios from 'axios'
import Cookies from 'js-cookie'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import getFallbackLanguage, { getLanguageFromUrl } from 'helpers/getFallbackLanguage'
import { PERMISSION_TO_VIEW_EV_MENU } from 'constants/permissions'
import { setDataLayerMultipleValues } from 'helpers/setDataLayerValue'
import { DATALAYER_KEYS, DATALAYER_VALUES } from 'constants/dataLayer'
import { BRAND_BP, BRAND_ARAL } from 'constants/brands'
import { ROUTE_ADMIN, ROUTE_LOGOUT } from 'src/constants/routes'
import { IS_ADMIN, ACCESS_LVL, ACCESS_LVL_CODE, IDP_TOKEN } from 'constants/localStorage'
import { ONEFLEETMIGRATIONSTATUS, ISONEFLEETMIGRATED } from 'constants/localStorage'

class UserStore {
  localStorage = window.localStorage
  userPermissions = null
  authorities = null
  hierarchy = null
  preferences = null
  user = null
  locale = getLanguageFromUrl() || Cookies.get('bpf-locale') || getFallbackLanguage()
  hierarchyCount = null
  brand = getBrandFromDomain()
  selectedAuthority = null
  selectedHierarchy = null
  userIsInitialised = false
  userIsReady = false
  isAdmin = localStorage.getItem(IS_ADMIN) === 'true'
  impersonatedUser = Cookies.get('bpf-imp')
  onefleetmigratedstatus = 0
  isonefleetmigrated = false
  isAck
  constructor() {
    makeObservable(this, {
      userIsReady: observable,
      isAdmin: observable,
      impersonatedUser: observable,
      authorities: observable,
      hierarchy: observable,
      locale: observable,
      preferences: observable,
      user: observable,
      userPermissions: observable,
      brand: observable,
      selectedAuthority: observable,
      selectedHierarchy: observable,
      onefleetmigratedstatus: observable,
      isonefleetmigrated: observable,
      dateFormat: computed,
      name: computed,
      setUserIsInitialised: action,
      setUserIsReady: action,
      selectedAuthorityId: computed,
      setSelectedAuthority: action,
      setSelectedHierarchy: action,
      setAuthorities: action,
      setHierarchy: action,
      setBrand: action,
      setHierarchyCount: action,
      setLocale: action,
      setPreferences: action,
      setUserDetails: action,
      setUserPermissions: action,
      setIsAdmin: action,
      setImpersonatedUser: action,
      setOnefleetMigratedStatus: action,
      setIsOneFleetMigrated: action
    })
  }

  async init() {
    try {
      this.setIsAdmin(localStorage.getItem(IS_ADMIN) === 'true')
      this.setImpersonatedUser(Cookies.get('bpf-imp'))
      this.setOnefleetMigratedStatus(parseInt(localStorage.getItem(ONEFLEETMIGRATIONSTATUS)))
      this.setIsOneFleetMigrated(localStorage.getItem(ISONEFLEETMIGRATED))
      await this.configureUserStore()
    } catch (e) {
      console.error('userStore init error -> logout', e)
      window.location.assign(window.location.protocol + '//' + window.location.host + '/' + this.locale + ROUTE_LOGOUT)
    }
  }

  async configureUserStore() {
    const promises = []
    // If normal user or an admin impersonating a user
    if (!this.isAdmin || this.impersonatedUser) {
      if (this.userIsInitialised) return
      this.setUserIsInitialised(true)
      this.setUserIsReady(false)

      // TODO -> this one is only once when user is setup (refreshed only in case of impersonation action)
      this.getPreferences()

      if (!this.hierarchy) {
        const hierarchyData = await this.getHierarchy()
        const locale = hierarchyData.data.locale.split('_')[0]
        Cookies.remove('bpf-locale')
        Cookies.set('bpf-locale', locale, { secure: true })
        localStorage.removeItem(ONEFLEETMIGRATIONSTATUS)
        localStorage.removeItem(ISONEFLEETMIGRATED)
        localStorage.setItem(ONEFLEETMIGRATIONSTATUS, hierarchyData.data.oneFleetMigratedStatus)
        localStorage.setItem(ISONEFLEETMIGRATED, hierarchyData.data.isOneFleetMigrated)
        this.setOnefleetMigratedStatus(hierarchyData.data.oneFleetMigratedStatus)
        this.setIsOneFleetMigrated(hierarchyData.data.isOneFleetMigrated)
        const currentLocale = getLanguageFromUrl()
        if (currentLocale !== locale) {
          const urlWithoutLocale = window.location.pathname.split('/').slice(2)
          history.pushState({}, '', ['', locale, urlWithoutLocale].join('/'))
        }
        this.setLocale(locale)

        // build Hierarchy tree from explicit and definition data received
        const hierarchy = {
          authorities: hierarchyData.data.authorities.map(auth => ({
            ...auth,
            opuCode: auth.authorityId.substr(0, 1),
            ISOCountryCode: hierarchyData.data.isoCodeLookup[auth.authorityId.substr(0, 1)]
          })),
          parents: [], // TEMPORARY -> set to hierarchyData.data.parents when ready for cross MS testing (some users are set with groups already) >> check below where added to evis users only
          groups: [] // TEMPORARY -> set to hierarchyData.data.groups when ready for cross MS testing (some users are set with groups already)
        }

        // adding 'overview group' -> if user has a single role across all access (avoid dmixed filtered result that would be confusing in the UI)
        if (hierarchyData.data.userRoles.length == 1) {
          // TEMPORARY -> restrict to Evis depot users so determine depotRole value
          const host = window.location.host
          let depotRole = 1570
          if (host.includes('local') || host.includes('dev') || host.includes('preprod')) depotRole = 1560

          // only add if relevant ie: if all auths are not under a single parent or group
          let overviewAccess = true
          const authListString = JSON.stringify(hierarchyData.data.authorities.map(auth => auth.authorityId).sort())
          hierarchyData.data.parents.forEach(par => {
            if (JSON.stringify(par.authorityIds.sort()) == authListString) overviewAccess = false
          })
          hierarchyData.data.groups.forEach(group => {
            const groupAuthList = []
            if (group.parents) {
              group.parents.forEach(parent => {
                hierarchyData.data.parents
                  .find(par => par.parentId == parent)
                  .authorities.forEach(authId => {
                    if (!groupAuthList.includes(authId)) groupAuthList.push(authId)
                  })
              })
            }
            if (group.authorities) {
              group.authorities.forEach(auth => {
                if (!groupAuthList.includes(auth.authorityId)) groupAuthList.push(auth.authorityId)
              })
            }
            if (JSON.stringify(groupAuthList.sort()) == authListString) overviewAccess = false
          })

          // TEMPORARY -> add overview group to evis users only
          if (hierarchyData.data.userRoles[0] != depotRole) overviewAccess = false

          if (overviewAccess) hierarchy.groups.unshift({ groupId: 'ALL', groupName: '[Overview]' })

          // TEMPORARY -> add parents view to evis users only
          if (hierarchyData.data.userRoles[0] == depotRole) hierarchy.parents = hierarchyData.data.parents
        }

        this.setHierarchy(hierarchy)

        let selectedHierarchy = null
        if (this.localStorage.getItem(ACCESS_LVL) && this.localStorage.getItem(ACCESS_LVL_CODE)) {
          if (this.localStorage.getItem(ACCESS_LVL) === 'A') {
            selectedHierarchy = hierarchy.authorities.find(
              auth => auth.authorityId === this.localStorage.getItem(ACCESS_LVL_CODE)
            )
          }
          if (this.localStorage.getItem(ACCESS_LVL) === 'P') {
            selectedHierarchy = hierarchy.parents.find(
              parent => parent.parentId === this.localStorage.getItem(ACCESS_LVL_CODE)
            )
          }
          if (this.localStorage.getItem(ACCESS_LVL) === 'G') {
            selectedHierarchy = hierarchy.groups.find(
              group => group.groupId === this.localStorage.getItem(ACCESS_LVL_CODE)
            )
          }

          if (selectedHierarchy) {
            selectedHierarchy.accessLevel = this.localStorage.getItem(ACCESS_LVL)
            selectedHierarchy.accessLevelCode = this.localStorage.getItem(ACCESS_LVL_CODE)
          }
        }

        if (!selectedHierarchy) {
          if (hierarchy.groups.length) {
            selectedHierarchy = {
              ...hierarchy.groups[0],
              accessLevel: 'G',
              accessLevelCode: hierarchy.groups[0].groupId
            }
          } else if (hierarchy.parents.length) {
            selectedHierarchy = {
              ...hierarchy.parents[0],
              accessLevel: 'P',
              accessLevelCode: hierarchy.parents[0].parentId
            }
          } else {
            selectedHierarchy = {
              ...hierarchy.authorities[0],
              accessLevel: 'A',
              accessLevelCode: hierarchy.authorities[0].authorityId
            }
          }
        }

        this.setSelectedHierarchy(selectedHierarchy)

        promises.push(this.getUserPermissions(selectedHierarchy.accessLevelCode, selectedHierarchy.accessLevel))
        promises.push(this.getUserDetails())
      } else {
        promises.push(
          this.getUserPermissions(this.selectedHierarchy.accessLevelCode, this.selectedHierarchy.accessLevel)
        )
      }

      const responses = await Promise.all(promises)
      let isAck = responses[0].data.masthead.isAck !== '' ? responses[0].data.masthead.isAck : ''
      this.setisAck(isAck)
      const userPermissions = responses[0].data.masthead.permissions
        .map(permission => (Object.values(permission)[1] ? Object.keys(permission)[1] : undefined))
        .filter(perms => !!perms)

      const isEVUser = userPermissions.includes(PERMISSION_TO_VIEW_EV_MENU)

      this.setUserPermissions(userPermissions)

      if (responses[1]) {
        this.setUserDetails(responses[1].data.userDetails)
        let brand = responses[1].data.userDetails?.brand?.toLowerCase() == 'ar' ? BRAND_ARAL : BRAND_BP
        brand = brand.toLowerCase()
        this.setBrand(brand)
        const dataLayerPairs = {
          [DATALAYER_KEYS.CUSTOMER_TYPE]: isEVUser
            ? DATALAYER_VALUES.CUSTOMER_TYPE_EV
            : DATALAYER_VALUES.CUSTOMER_TYPE_FUEL,
          [DATALAYER_KEYS.BRAND]: this.brand,
          [DATALAYER_KEYS.USER_ID]: toJS(this.user.uniqueId)
        }

        if (this.selectedHierarchy.accessLevel == 'A')
          dataLayerPairs[DATALAYER_KEYS.CUSTOMER_ID] = toJS(this.selectedHierarchy.accessLevelCode)
        if (this.selectedHierarchy.accessLevel == 'P')
          dataLayerPairs[DATALAYER_KEYS.PARENT_ID] = toJS(this.selectedHierarchy.accessLevelCode)

        setDataLayerMultipleValues(dataLayerPairs)
      }

      this.setUserIsReady(true)
      this.setUserIsInitialised(false)
    }
  }

  get selectedAuthorityId() {
    return this.selectedHierarchy?.authorityId
  }

  get name() {
    return {
      firstName: this.user?.firstName || '',
      lastName: this.user?.lastName || ''
    }
  }

  get dateFormat() {
    const preference = this.user?.preferences?.dateFormat?.value || 'DD/MM/YYYY hh:mm:ss'

    // Prepare for 'light-date' formatting
    return preference
      .replace('DD', '{dd}')
      .replace('MM', '{MM}')
      .replace('YYYY', '{yyyy}')
      .replace('YY', '{yy}')
      .replace('mm', '{mm}')
      .replace('HH', '{HH}')
      .replace('hh', '{HH}')
      .replace('ss', '{ss}')
      .replace('SSS', '{SSS}')
  }

  async getPreferences() {
    let url = `/api/users/userpreferences`
    const response = await axios({
      method: 'get',
      url: url
    })

    this.setPreferences(response.data)
  }

  async updatePreferences(type, columns) {
    let url = (url = `/api/users/userpreferences/${type}`)
    const response = await axios({
      method: 'put',
      url: url,
      data: columns
    })

    this.setPreferences(response.data)
  }

  setSelectedAuthority(authority) {
    this.localStorage.setItem(ACCESS_LVL_CODE, authority?.authorityId)
    this.selectedAuthority = authority
    this.userPermissions = []
    this.userIsReady = false
  }

  setSelectedHierarchy(hierarchy) {
    this.localStorage.setItem(ACCESS_LVL, hierarchy.accessLevel)
    this.localStorage.setItem(ACCESS_LVL_CODE, hierarchy.accessLevelCode)
    this.setSelectedAuthority(hierarchy.accessLevel == 'A' ? hierarchy : null)
    this.selectedHierarchy = hierarchy
    this.userPermissions = []
    this.userIsReady = false
  }

  setPreferences(preferences) {
    this.preferences = preferences
  }

  setUserIsReady(userIsReady) {
    this.userIsReady = userIsReady
  }

  setIsAdmin(isAdmin) {
    if (isAdmin) {
      this.setLocale(getFallbackLanguage(true))
    } else {
      if (this.impersonatedUser) this.setImpersonatedUser('')
    }
    this.isAdmin = isAdmin
  }
  setOnefleetMigratedStatus(onefleetmigratedstatus) {
    this.onefleetmigratedstatus = onefleetmigratedstatus
  }
  setIsOneFleetMigrated(isonefleetmigrated) {
    this.isonefleetmigrated = isonefleetmigrated == 'true' ? true : false
  }
  setImpersonatedUser(impersonatedUser) {
    if (!impersonatedUser) {
      Cookies.remove('bpf-imp')
      if (this.impersonatedUser) {
        const locale = getFallbackLanguage(true)
        if (this.locale != locale) {
          history.pushState({}, '', ['/', locale, ROUTE_ADMIN].join(''))
          this.setLocale(locale)
          Cookies.set('bpf-locale', locale, { secure: true })
        }
      }
    }
    if (impersonatedUser !== this.impersonatedUser && this.isAdmin) {
      Cookies.set('bpf-imp', impersonatedUser, { secure: true })
      this.setHierarchy(null)
    }
    this.impersonatedUser = impersonatedUser
  }

  setLocale(locale) {
    this.locale = locale
  }

  setAuthorities(authorities) {
    this.authorities = authorities
  }

  setHierarchy(hierarchy) {
    this.hierarchy = hierarchy
  }

  setUserDetails(user) {
    this.user = user
  }

  setUserPermissions(permissions) {
    this.userPermissions = permissions
  }

  setUserIsInitialised(userIsInitialised) {
    this.userIsInitialised = userIsInitialised
  }
  setisAck(isAck) {
    this.isAck = isAck
  }
  setBrand(brand) {
    Cookies.set('bpf-brand', brand, { secure: true })
    this.brand = brand
  }

  setHierarchyCount(hierarchyCount) {
    this.hierarchyCount = hierarchyCount
  }
  // not found any references below method that's why commenting
  // getAuthorities = () =>
  //   axios({
  //     method: 'post',
  //     url: '/xapi/bp-ds-userservices-rio-xapi/api/groups/group/authorities',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: {
  //       start: 1,
  //       limit: 500,
  //       columnSorting: ['authorityNumber', 'desc'],
  //       accessAreaLevel: 'masthead',
  //       permissionRole: true,
  //       invoiceLevelFilter: 'All',
  //       groupId: [],
  //       parentId: [],
  //       groupDirectAuthoritiesOnly: true
  //     }
  //   })
  getHierarchy = async () => {
    return await axios({
      method: 'get',
      url: '/api/users/users/hierarchy'
    })
  }

  getHierarchyCount = () => {
    const params = {
      accessLevel: 'A',
      isDirect: false
    }

    return axios({
      method: 'get',
      url: '/api/users/users/-1/count',
      params
    })
  }

  authenticate = async () => {
    const response = await axios({
      method: 'post',
      url: '/api/users/users/profile',
      data: {
        brand: getBrandFromDomain(),
        accessToken: window.localStorage.getItem(IDP_TOKEN)
      }
    })

    this.setIsAdmin(response.data.isAdmin)
  }

  getUserDetails = () =>
    axios({
      method: 'get',
      // Been round in circles with this, it forces cache, we struggle to remove
      // For now this tactical fix absolutely forces it to be unique...
      url: '/api/users/users/details'
    })

  getUserLocale = (authorityId, accessLevel = 'A') =>
    axios.get('/api/users/users/loggedinuser', {
      params: {
        accessLevel,
        accessLevelCode: authorityId
      }
    })

  getUserPermissions = (authorityId, accessLevel = 'A') => {
    const params = {
      accessLevelCode: authorityId,
      accessLevel
    }

    if (accessLevel === 'A') {
      params.authorityIds = [authorityId]
    }

    if (accessLevel === 'P') {
      params.parentIds = [authorityId]
    }

    return axios.get(`/api/users/users/permissions`, { params })
  }

  updateUserDetails = user =>
    axios({
      method: 'post',
      url: `/api/users/users/${this.user?.uId ?? -1}`,
      data: {
        groupAdded: [],
        groupRemoved: [],
        groupEdited: [],
        parentAdded: [],
        parentEdited: [],
        parentRemoved: [],
        authoritiesAdded: [],
        authoritiesRemoved: [],
        authoritiesEdited: [],
        uId: this.user?.uId,
        user
      }
    })
}

export default UserStore
