import axios from 'axios'
import { SEND_TO_ADDRESS } from '../constants/constants'

const YES = 'Y'
const NO = 'N'

const buildRequest = ({ formData, authority, authorityDetails }) => {
  const { configuration: config, deliveryAddress, pinOption } = authorityDetails
  const { active: activePin, ...pinDetails } = formData.pin

  const isPinByPost = activePin === 4 || (activePin === 0 && pinOption.pinDerivation === '0')

  let evCharging = ''

  if (formData.fuelAndCharge === YES) {
    evCharging = authorityDetails.cardPlasticOption
  }

  const request = {
    cardSetup: {
      authority: +authority.authorityId.substring(1),
      authorityId: authority.authorityId,
      parentNumber: authorityDetails.parentNumber ? `${authorityDetails.parentNumber}` : null,
      activatebp: config.cardSetup.activateBPMe.defaultValue,
      activationcode: '',
      confirmActivationCode: '',
      customerNumber: authorityDetails.customerNumber,
      opuCode: authorityDetails.opuCode,
      parentId:
        authorityDetails.parentNumber === null ? null : `${authorityDetails.opuCode}${authorityDetails.parentNumber}`,
      countryCode: authorityDetails.countryCode,
      ...formData.cardSetup
    },
    carddetails: {
      sendToAddress: formData.cardDetails.address.addressLine1 == '' ? authorityDetails.sendToAddress : '2',
      languageCode: authorityDetails.languageCode,
      serviceCode: authorityDetails.preSetServiceCode || authorityDetails.serviceCode,
      cardProgramNumber: authorityDetails.cardProgramNumber,
      ivmh: formData.gasoleoProfessional || config.cardDetails.ivmh.defaultValue,
      ivmhstartDate: authorityDetails.ivmhstartDate,
      ivmhendDate: authorityDetails.ivmhendDate,
      driverId: '',
      ppdScheme: authorityDetails.ppdScheme,
      ppdSchemeStartDate: authorityDetails.ppdSchemeStartDate,
      ppdSchemeEndDate: authorityDetails.ppdSchemeEndDate,
      targetNeutral:
        (authorityDetails.targetNeutral?.trim() || authorityDetails.columnContext.targetNeutral) === YES ? YES : NO,
      evCharging,
      address: authorityDetails.sendToAddress === SEND_TO_ADDRESS.CARD_HOLDER ? deliveryAddress : {},
      ...formData.cardDetails
    },
    cardembossing: {
      line0: authorityDetails.embossingLines.line0.content,
      ...formData.cardEmbossing
    },
    region: {
      costcenterNumber: config.region.costcenter || '0',
      address: authorityDetails.sendToAddress === SEND_TO_ADDRESS.COST_CENTRE ? deliveryAddress : {},
      ...formData.region
    },
    pin: {
      pinDerivation: activePin === 4 ? '0' : `${activePin}` || 0,
      pinReq:
        (pinOption.pinReq === '4' && activePin === 1 && 1) ||
        (pinOption.pinReq === '4' && activePin === 4 && 0) ||
        pinOption.pinReq,
      ...(isPinByPost
        ? {
            pinByPost: YES,
            address: deliveryAddress
          }
        : {
            pinByPost: NO
          }),
      ...pinDetails
    },
    summary: {
      expediteCard: NO,
      expiryDate: {
        expiryMonth: authorityDetails.expiryMonth,
        expiryYear: authorityDetails.expiryYear
      }
    },
    ...(formData.cardSetup.restriction === YES && buildRestrictionRequest(formData))
  }

  return request
}

export const buildRestrictionRequest = ({ restrictionDetails }) => {
  const { profileId, profileName } = restrictionDetails

  const request = {
    restrictionDetail: {
      saveProfile: false,
      profileId: Number(profileId),
      profileName,
      restrictionTypes: [],
      existingProfile: true
    }
  }

  return request
}

export const submitRequest = data => buildRequest(data)

export const submitReplacementCardRequest = async (formData, replacementCardDetails, authority, authorityDetails) => {
  const request = buildRequest({ formData, authority, authorityDetails })
  request.carddetails.reasonCode = replacementCardDetails.reasonCode
  request.carddetails.cardnumber = replacementCardDetails.cardSerialNumber
  request.cardSetup.vcardSeialNo = replacementCardDetails.cardSerialNumber

  try {
    const { data } = await axios({
      method: 'post',
      url: '/api/cards/replacecardorder',
      data: {
        authorityId: authority.authorityId,
        replacecard: [request]
      }
    })

    return data
  } catch (e) {
    console.warn(e)
  }
}
